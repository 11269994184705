<template>
  <el-main>
    <el-button style="margin-bottom: 10px" @click="$router.push('/extension/flashSale/add')" size="small" type="primary" icon="el-icon-plus">添加限时抢购</el-button>
    <!-- 表单 -->
    <el-form class="el-form-search" label-width="110px">
      <el-form-item label="活动名称：">
        <el-input size="small" placeholder="请输入活动名称" v-model="searchForm.title"></el-input>
      </el-form-item>
      <el-form-item label="活动状态：">
        <el-select v-model="searchForm.status" size="small">
          <el-option :label="item.label" :value="item.val" v-for="item in status" :key="item.val"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="getList">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <!-- 列表 -->
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" :key="key" fit>
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="title" label="活动名称" align="center"></el-table-column>
      <el-table-column prop="status" label="活动状态" align="center">
        <template v-slot="{ row }">
          {{ status.find(item => item.val == row.status).label }}
        </template>
      </el-table-column>
      <el-table-column prop="time" label="活动时间" align="center">
        <template v-slot="{ row }">
          {{ getDateformat(row.start_time) }}
          <br />
          {{ getDateformat(row.end_time) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button v-if="row.status === 2" @click="lookInfo(row.id)" type="text" size="small">查看</el-button>
          <el-button v-if="row.status === 2" @click="stopActively(row.id, -1)" type="text" size="small">终止</el-button>
          <el-button v-if="row.status === -1 && row.end_time > nowTime" @click="stopActively(row.id, 1)" type="text" size="small">开启</el-button>
          <el-button v-if="row.status !== 2" @click="$router.push({ path: '/extension/flashSale/edit', query: { id: row.id } })" type="text" size="small">编辑</el-button>
          <el-button v-if="row.status !== 2" @click="deleteData(row.id)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      getDateformat,
      searchForm: {
        title: '',
        status: 0,
        page: 1,
        rows: 10,
      },
      list: [],
      total_number: 0,
      status: [
        {
          label: '已终止',
          val: -1,
        },
        {
          label: '全部',
          val: 0,
        },
        {
          label: '未开始',
          val: 1,
        },
        {
          label: '进行中',
          val: 2,
        },
        {
          label: '已过期',
          val: 3,
        },
        {
          label: '已下架',
          val: 4,
        },
      ],
      nowTime: Math.floor(new Date().getTime() / 1000),
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      let obj = {
        page: this.searchForm.page,
        rows: this.searchForm.rows,
      };
      if (this.searchForm.title) obj.title = this.searchForm.title;
      if (this.searchForm.status) obj.status = this.searchForm.status;

      this.$axios.post(this.$api.flashSale.getList, obj).then(res => {
        if (res.code == 0) {
          res.result.list.map(item => {
            if (item.status === 1) {
              let nowTime = Math.floor(new Date().getTime() / 1000);
              if (nowTime >= item.start_time && nowTime < item.end_time) item.status = 2;
              if (nowTime >= item.end_time) item.status = 3;
            }
          });
          this.list = res.result.list;
          this.total_number = res.result.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    cancelSearch() {
      this.searchForm = {
        title: '',
        status: 0,
        page: 1,
        rows: 10,
      };
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    deleteData(id) {
      this.$confirm('此操作将永久删除该活动, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$axios.post(this.$api.flashSale.delActivity, { id }).then(res => {
            if (res.code == 0) {
              this.$message.success('删除成功');
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    stopActively(id, status) {
      let str = status === -1 ? '此操作将终止该活动, 是否继续?' : '此操作将开启该活动, 是否继续?';
      this.$confirm(str, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$axios.post(this.$api.flashSale.action, { id, status }).then(res => {
            if (res.code == 0) {
              this.$message.success('操作成功');
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    // 查看活动信息
    lookInfo(id) {
      this.$router.push('/extension/flashSale/info?id=' + id);
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
.banner_img {
  width: 200px;
  height: 70px;
  object-fit: contain;
}
</style>
